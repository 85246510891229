const iconBlockAdapter = (data) => {
  return {
    text: data.text,
    title: data.title,
    icon: data.icon,
  };
};

export default ({ data }) => {
  return {
    title: data.title,
    subtitle: data.subtitle,
    items: data.items?.map(iconBlockAdapter),
  };
};
