const adapters = {
  Text: 'text',
  Head: 'head',
  Files: 'files',
  TextComplex: 'text-complex',
  CertificateImage: 'certificate-image',
  AboutRequisites: 'about-requisites',
  Title: 'title',
  BanksSoftpos: 'banks-soft-pos',
  AboutLinks: 'about-links',
  ContactsList: 'contacts-list',
  ContactsSocials: 'contacts-socials',
  ListIconBlocks: 'list-icon-blocks',
  ListChart: 'list-chart',
  Questions: 'questions',
  ListAlphabet: 'list-alphabet',
  SecurityColumns: 'security-columns',
  SecurityCards: 'security-cards',
  Image: 'image',
  SecurityDigest: 'security-digest',
  ListNumbered: 'list-numbered',
  FeedbackForm: false,
  KnowledgeStaff: 'knowledge-staff',
  Table: 'table',
  PressInfo: 'press-info',
  CertificateMethods: 'certificate-methods',
  ListNumberedImages: 'list-numbered-images',
};

export default async ({ data, items }) => {
  return {
    title: data.title,
    icon: data.icon,
    topLine: data.top_line,
    bottomLine: data.bottom_line,
    sections: await getSectionAdapters(items, adapters),
  };
};
