import { default as _91_46_46_46slug_93ek9sZLmcU3Meta } from "/app/dev/pages/[...slug].vue?macro=true";
import { default as indexOlKBEPCHASMeta } from "/app/dev/pages/about/assessment-of-workplaces/index.vue?macro=true";
import { default as indexjUL5niVKjAMeta } from "/app/dev/pages/about/company/index.vue?macro=true";
import { default as indextivZ82hG0uMeta } from "/app/dev/pages/about/contacts/requisites/index.vue?macro=true";
import { default as indexLJHBdWVdEvMeta } from "/app/dev/pages/about/corporate_governance/trust-line/index.vue?macro=true";
import { default as indexP33CIkw9dcMeta } from "/app/dev/pages/about/deyatelnost-v-oblasti-informatsionnykh-tekhnologiy/index.vue?macro=true";
import { default as indexQ0inxfRdQMMeta } from "/app/dev/pages/about/investor-relations/index.vue?macro=true";
import { default as indexLur4RRTg6kMeta } from "/app/dev/pages/about/official_sites/index.vue?macro=true";
import { default as indexMk8GqhuEY9Meta } from "/app/dev/pages/about/processing-of-personal-data/index.vue?macro=true";
import { default as indexOznvcuqrXjMeta } from "/app/dev/pages/about/sootvetstvie-pci-dss/index.vue?macro=true";
import { default as indexQY7VTpK5hBMeta } from "/app/dev/pages/banks/acquiring/index.vue?macro=true";
import { default as indexCYzlPTClAlMeta } from "/app/dev/pages/banks/analytics/index.vue?macro=true";
import { default as indexS9VstSraI6Meta } from "/app/dev/pages/banks/guides/index.vue?macro=true";
import { default as indexnG99USfamIMeta } from "/app/dev/pages/banks/rules/index.vue?macro=true";
import { default as indexo3eDSkhwnoMeta } from "/app/dev/pages/cards-mir/certificates/index.vue?macro=true";
import { default as indexfHFxJOkRlGMeta } from "/app/dev/pages/cards-mir/perechen-operatorov-uslug-platezhnoy-infrastruktury/index.vue?macro=true";
import { default as index7zYwCo4r9UMeta } from "/app/dev/pages/cards-mir/security/about-fraud/index.vue?macro=true";
import { default as index5QQolmyU6KMeta } from "/app/dev/pages/cards-mir/security/index.vue?macro=true";
import { default as indexSEX8xnwv6vMeta } from "/app/dev/pages/cards-mir/security/security-program/after-hack/index.vue?macro=true";
import { default as indexuV2nVU9scXMeta } from "/app/dev/pages/cards-mir/security/security-program/index.vue?macro=true";
import { default as index8BldVPSOSvMeta } from "/app/dev/pages/cards-mir/security/security-program/program-tsp/index.vue?macro=true";
import { default as indexcq2d72iBl7Meta } from "/app/dev/pages/cards-mir/security/standart-pcidss/index.vue?macro=true";
import { default as indextH2lyr3x0fMeta } from "/app/dev/pages/cards-mir/security/standart-pcidss/self-assessment/index.vue?macro=true";
import { default as indextv0E7CAPOvMeta } from "/app/dev/pages/cards-mir/soft/index.vue?macro=true";
import { default as indexbG3VfG0xnbMeta } from "/app/dev/pages/cards-mir/terms-and-tariffs/index.vue?macro=true";
import { default as indexDvMDqNQkCoMeta } from "/app/dev/pages/certificates/index.vue?macro=true";
import { default as constructorWZkEsSVhtZMeta } from "/app/dev/pages/constructor.vue?macro=true";
import { default as indexy963EqmO38Meta } from "/app/dev/pages/contacts/index.vue?macro=true";
import { default as indexxMQSjeu6c2Meta } from "/app/dev/pages/ecert/index.vue?macro=true";
import { default as index0TSJexJi8rMeta } from "/app/dev/pages/echeck/index.vue?macro=true";
import { default as exampleV3rLExgndNMeta } from "/app/dev/pages/example.vue?macro=true";
import { default as index4PZzyyUOK2Meta } from "/app/dev/pages/index.vue?macro=true";
import { default as indexNGFYDZ7XQnMeta } from "/app/dev/pages/knowledge/events/[eventSlug]/form/index.vue?macro=true";
import { default as indexIgz0bzV4QsMeta } from "/app/dev/pages/knowledge/events/[eventSlug]/index.vue?macro=true";
import { default as indexDf6hdNwMlgMeta } from "/app/dev/pages/knowledge/events/index.vue?macro=true";
import { default as indexoa1pyOPGPEMeta } from "/app/dev/pages/knowledge/index.vue?macro=true";
import { default as index8h4CMEaVaJMeta } from "/app/dev/pages/press-center/details/[articleSlug]/index.vue?macro=true";
import { default as indexW0vxsFSus1Meta } from "/app/dev/pages/press-center/index.vue?macro=true";
import { default as indexFgLGuxSiddMeta } from "/app/dev/pages/referral/[referralSlug]/form/index.vue?macro=true";
import { default as indexS6UbZSPiOEMeta } from "/app/dev/pages/referral/[referralSlug]/index.vue?macro=true";
import { default as indexCgM4TyReMFMeta } from "/app/dev/pages/referral/index.vue?macro=true";
import { default as indexVX8x5puRjfMeta } from "/app/dev/pages/search/index.vue?macro=true";
import { default as indexPQuIn3emu8Meta } from "/app/dev/pages/tk/index.vue?macro=true";
import { default as index7hzkxR2JOGMeta } from "/app/dev/pages/ustoychivoe-razvitie/index.vue?macro=true";
import { default as project_45_91slug_93YDb85iY48uMeta } from "/app/dev/pages/ustoychivoe-razvitie/project-[slug].vue?macro=true";
import { default as indexRrSHkV2x5JMeta } from "/app/dev/pages/vacancies/[vacancySlug]/form/index.vue?macro=true";
import { default as index8NXBqSbZGQMeta } from "/app/dev/pages/vacancies/[vacancySlug]/index.vue?macro=true";
import { default as indexr4r3UB0tQlMeta } from "/app/dev/pages/vacancies/form/index.vue?macro=true";
import { default as indexCjVzrAMJtBMeta } from "/app/dev/pages/vacancies/index.vue?macro=true";
export default [
  {
    name: "slug",
    path: "/:slug(.*)*",
    component: () => import("/app/dev/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "about-assessment-of-workplaces",
    path: "/about/assessment-of-workplaces",
    component: () => import("/app/dev/pages/about/assessment-of-workplaces/index.vue").then(m => m.default || m)
  },
  {
    name: "about-company",
    path: "/about/company",
    component: () => import("/app/dev/pages/about/company/index.vue").then(m => m.default || m)
  },
  {
    name: "about-contacts-requisites",
    path: "/about/contacts/requisites",
    component: () => import("/app/dev/pages/about/contacts/requisites/index.vue").then(m => m.default || m)
  },
  {
    name: "about-corporate_governance-trust-line",
    path: "/about/corporate_governance/trust-line",
    component: () => import("/app/dev/pages/about/corporate_governance/trust-line/index.vue").then(m => m.default || m)
  },
  {
    name: "about-deyatelnost-v-oblasti-informatsionnykh-tekhnologiy",
    path: "/about/deyatelnost-v-oblasti-informatsionnykh-tekhnologiy",
    component: () => import("/app/dev/pages/about/deyatelnost-v-oblasti-informatsionnykh-tekhnologiy/index.vue").then(m => m.default || m)
  },
  {
    name: "about-investor-relations",
    path: "/about/investor-relations",
    component: () => import("/app/dev/pages/about/investor-relations/index.vue").then(m => m.default || m)
  },
  {
    name: "about-official_sites",
    path: "/about/official_sites",
    component: () => import("/app/dev/pages/about/official_sites/index.vue").then(m => m.default || m)
  },
  {
    name: "about-processing-of-personal-data",
    path: "/about/processing-of-personal-data",
    component: () => import("/app/dev/pages/about/processing-of-personal-data/index.vue").then(m => m.default || m)
  },
  {
    name: "about-sootvetstvie-pci-dss",
    path: "/about/sootvetstvie-pci-dss",
    component: () => import("/app/dev/pages/about/sootvetstvie-pci-dss/index.vue").then(m => m.default || m)
  },
  {
    name: "banks-acquiring",
    path: "/banks/acquiring",
    component: () => import("/app/dev/pages/banks/acquiring/index.vue").then(m => m.default || m)
  },
  {
    name: "banks-analytics",
    path: "/banks/analytics",
    component: () => import("/app/dev/pages/banks/analytics/index.vue").then(m => m.default || m)
  },
  {
    name: "banks-guides",
    path: "/banks/guides",
    component: () => import("/app/dev/pages/banks/guides/index.vue").then(m => m.default || m)
  },
  {
    name: "banks-rules",
    path: "/banks/rules",
    component: () => import("/app/dev/pages/banks/rules/index.vue").then(m => m.default || m)
  },
  {
    name: "cards-mir-certificates",
    path: "/cards-mir/certificates",
    component: () => import("/app/dev/pages/cards-mir/certificates/index.vue").then(m => m.default || m)
  },
  {
    name: "cards-mir-perechen-operatorov-uslug-platezhnoy-infrastruktury",
    path: "/cards-mir/perechen-operatorov-uslug-platezhnoy-infrastruktury",
    component: () => import("/app/dev/pages/cards-mir/perechen-operatorov-uslug-platezhnoy-infrastruktury/index.vue").then(m => m.default || m)
  },
  {
    name: "cards-mir-security-about-fraud",
    path: "/cards-mir/security/about-fraud",
    meta: index7zYwCo4r9UMeta || {},
    component: () => import("/app/dev/pages/cards-mir/security/about-fraud/index.vue").then(m => m.default || m)
  },
  {
    name: "cards-mir-security",
    path: "/cards-mir/security",
    meta: index5QQolmyU6KMeta || {},
    component: () => import("/app/dev/pages/cards-mir/security/index.vue").then(m => m.default || m)
  },
  {
    name: "cards-mir-security-security-program-after-hack",
    path: "/cards-mir/security/security-program/after-hack",
    meta: indexSEX8xnwv6vMeta || {},
    component: () => import("/app/dev/pages/cards-mir/security/security-program/after-hack/index.vue").then(m => m.default || m)
  },
  {
    name: "cards-mir-security-security-program",
    path: "/cards-mir/security/security-program",
    meta: indexuV2nVU9scXMeta || {},
    component: () => import("/app/dev/pages/cards-mir/security/security-program/index.vue").then(m => m.default || m)
  },
  {
    name: "cards-mir-security-security-program-program-tsp",
    path: "/cards-mir/security/security-program/program-tsp",
    meta: index8BldVPSOSvMeta || {},
    component: () => import("/app/dev/pages/cards-mir/security/security-program/program-tsp/index.vue").then(m => m.default || m)
  },
  {
    name: "cards-mir-security-standart-pcidss",
    path: "/cards-mir/security/standart-pcidss",
    meta: indexcq2d72iBl7Meta || {},
    component: () => import("/app/dev/pages/cards-mir/security/standart-pcidss/index.vue").then(m => m.default || m)
  },
  {
    name: "cards-mir-security-standart-pcidss-self-assessment",
    path: "/cards-mir/security/standart-pcidss/self-assessment",
    meta: indextH2lyr3x0fMeta || {},
    component: () => import("/app/dev/pages/cards-mir/security/standart-pcidss/self-assessment/index.vue").then(m => m.default || m)
  },
  {
    name: "cards-mir-soft",
    path: "/cards-mir/soft",
    component: () => import("/app/dev/pages/cards-mir/soft/index.vue").then(m => m.default || m)
  },
  {
    name: "cards-mir-terms-and-tariffs",
    path: "/cards-mir/terms-and-tariffs",
    component: () => import("/app/dev/pages/cards-mir/terms-and-tariffs/index.vue").then(m => m.default || m)
  },
  {
    name: "certificates",
    path: "/certificates",
    component: () => import("/app/dev/pages/certificates/index.vue").then(m => m.default || m)
  },
  {
    name: "constructor",
    path: "/constructor",
    meta: constructorWZkEsSVhtZMeta || {},
    component: () => import("/app/dev/pages/constructor.vue").then(m => m.default || m)
  },
  {
    name: "contacts",
    path: "/contacts",
    component: () => import("/app/dev/pages/contacts/index.vue").then(m => m.default || m)
  },
  {
    name: "ecert",
    path: "/ecert",
    component: () => import("/app/dev/pages/ecert/index.vue").then(m => m.default || m)
  },
  {
    name: "echeck",
    path: "/echeck",
    component: () => import("/app/dev/pages/echeck/index.vue").then(m => m.default || m)
  },
  {
    name: "example",
    path: "/example",
    meta: exampleV3rLExgndNMeta || {},
    component: () => import("/app/dev/pages/example.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: index4PZzyyUOK2Meta || {},
    component: () => import("/app/dev/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "knowledge-events-eventSlug-form",
    path: "/knowledge/events/:eventSlug()/form",
    component: () => import("/app/dev/pages/knowledge/events/[eventSlug]/form/index.vue").then(m => m.default || m)
  },
  {
    name: "knowledge-events-eventSlug",
    path: "/knowledge/events/:eventSlug()",
    component: () => import("/app/dev/pages/knowledge/events/[eventSlug]/index.vue").then(m => m.default || m)
  },
  {
    name: "knowledge-events",
    path: "/knowledge/events",
    component: () => import("/app/dev/pages/knowledge/events/index.vue").then(m => m.default || m)
  },
  {
    name: "knowledge",
    path: "/knowledge",
    component: () => import("/app/dev/pages/knowledge/index.vue").then(m => m.default || m)
  },
  {
    name: "press-center-details-articleSlug",
    path: "/press-center/details/:articleSlug()",
    component: () => import("/app/dev/pages/press-center/details/[articleSlug]/index.vue").then(m => m.default || m)
  },
  {
    name: "press-center",
    path: "/press-center",
    component: () => import("/app/dev/pages/press-center/index.vue").then(m => m.default || m)
  },
  {
    name: "referral-referralSlug-form",
    path: "/referral/:referralSlug()/form",
    component: () => import("/app/dev/pages/referral/[referralSlug]/form/index.vue").then(m => m.default || m)
  },
  {
    name: "referral-referralSlug",
    path: "/referral/:referralSlug()",
    component: () => import("/app/dev/pages/referral/[referralSlug]/index.vue").then(m => m.default || m)
  },
  {
    name: "referral",
    path: "/referral",
    component: () => import("/app/dev/pages/referral/index.vue").then(m => m.default || m)
  },
  {
    name: "search",
    path: "/search",
    component: () => import("/app/dev/pages/search/index.vue").then(m => m.default || m)
  },
  {
    name: "tk",
    path: "/tk",
    meta: indexPQuIn3emu8Meta || {},
    component: () => import("/app/dev/pages/tk/index.vue").then(m => m.default || m)
  },
  {
    name: "ustoychivoe-razvitie",
    path: "/ustoychivoe-razvitie",
    component: () => import("/app/dev/pages/ustoychivoe-razvitie/index.vue").then(m => m.default || m)
  },
  {
    name: "ustoychivoe-razvitie-project-slug",
    path: "/ustoychivoe-razvitie/project-:slug()",
    component: () => import("/app/dev/pages/ustoychivoe-razvitie/project-[slug].vue").then(m => m.default || m)
  },
  {
    name: "vacancies-vacancySlug-form",
    path: "/vacancies/:vacancySlug()/form",
    component: () => import("/app/dev/pages/vacancies/[vacancySlug]/form/index.vue").then(m => m.default || m)
  },
  {
    name: "vacancies-vacancySlug",
    path: "/vacancies/:vacancySlug()",
    component: () => import("/app/dev/pages/vacancies/[vacancySlug]/index.vue").then(m => m.default || m)
  },
  {
    name: "vacancies-form",
    path: "/vacancies/form",
    component: () => import("/app/dev/pages/vacancies/form/index.vue").then(m => m.default || m)
  },
  {
    name: "vacancies",
    path: "/vacancies",
    component: () => import("/app/dev/pages/vacancies/index.vue").then(m => m.default || m)
  }
]