import {
  imageUrlAdapter,
} from '@/adapters/constructor/utils/image';
const itemsAdapter = (data) => {
  return {
    num: data.num,
    title: data.title,
    text: data.text,
    bottomText: data.bottom,
    images: data.images?.items.map(imageUrlAdapter),
  };
};

export default ({ data }) => {
  return {
    text: data.text,
    items: data?.items.map(itemsAdapter),
  };
};
